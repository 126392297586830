import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [

/*
  {
    path: '/settings/point',
    name: 'Point',
    component: () => import('../backend/components/point/point.vue')
   },
   {
    path: '/settings/prizes',
    name: 'Prizes',
    component: () => import('../backend/components/prizes/prizes.vue')
   },
   {
    path: '/settings/code',
    name: 'PointCode',
    component: () => import('../backend/components/point-code/point-code.vue')
   },
   {
    path: '/users-management/roles',
    name: 'Roles',
    component: () => import('../backend/components/users-management/roles/roles.vue')
   },
   {
    path: '/users-management/users',
    name: 'Users',
    component: () => import('../backend/components/users-management/users/users.vue')
   },
   {
    path: '/users-management/permission',
    name: 'Permission',
    component: () => import('../backend/components/users-management/permission/permission.vue')
   },
   {
    path: '/customers/customers-list',
    name: 'Customers',
    component: () => import('../backend/components/customers/customers.vue')
   },
   {
    path: '/members/members-list',
    name: 'Members',
    component: () => import('../backend/components/members/members.vue')
   },
   {
    path: '/settings/products',
    name: 'Products',
    component: () => import('../backend/components/products/products.vue')
   },
   {
    path: '/sales/sales-list',
    name: 'Sales',
    component: () => import('../backend/components/sales-list/sales-list.vue')
   },


  // frontend
  {
      path: '/',
      name: 'Frontend',
      component: () => import('../frontend/auth/login.vue')
  },
  {
    path: '/:id/redeem',
    name: 'Redeem',
    component: () => import('../frontend/redeem/redeem.vue')
},
  {
    path: '/:id/profile',
    name: 'Profile',
    component: () => import('../frontend/profile/profile.vue')
}
,
  {
    path: '/prize',
    name: 'Prize',
    component: () => import('../frontend/prize/prize.vue')
},
*/
// bfc
//ADMIN
{
    path: '/admin',
    name: 'ADMIN',
    component: () => import('../components/bfc/admin/login/login.vue')
},
{
  path: '/admin/dashboard',
  name: 'ADMIN-DASHBOARD',
  component: () => import('../components/bfc/admin/dashboard/dashboard.vue')
},
{
  path: '/admin/case/:caseNo',
  name: 'ADMIN-VIEW-CASE',
  component: () => import('../components/bfc/admin/case/case.vue')
},
{
  path: '/admin/users/admin',
  name: 'ADMIN-LIST',
  component: () => import('../components/bfc/admin/users/admin/admin.vue')
},
{
  path: '/admin/users/lab',
  name: 'ADMIN-LAB-LIST',
  component: () => import('../components/bfc/admin/users/lab/lab.vue')
},

{
  path: '/admin/users/createLab',
  name: 'ADMIN-NEW-LAB-PROFILE',
  component: () => import('../components/bfc/admin/users/lab/create.vue')
},
{
  path: '/admin/users/create-admin',
  name: 'ADMIN-NEW-ADMIN-PROFILE',
  component: () => import('../components/bfc/admin/users/admin/create.vue')
},
{
  path: '/admin/users/edit-admin/:id',
  name: 'BFC-Edit-Admin',
  component: () => import('../components/bfc/admin/users/admin/create.vue')
},





{
  path: '/admin/users/doctor',
  name: 'ADMIN-DR-LIST',
  component: () => import('../components/bfc/admin/users/doctor/doctor.vue')
},

{
  path: '/admin/users/create-doctor',
  name: 'ADMIN-NEW-DR',
  component: () => import('../components/bfc/admin/users/doctor/create.vue')
},
{
  path: '/admin/users/edit-doctor/:id',
  name: 'ADMIN-EDIT-DR-PROFILE',
  component: () => import('../components/bfc/admin/users/doctor/create.vue')
},

{
  path: '/admin/users/edit-lab/:id',
  name: 'ADMIN-EDIT-LAB-PROFILE',
  component: () => import('../components/bfc/admin/users/lab/create.vue')
},

{
  path: '/admin/users/edit-inspector/:id',
  name: 'ADMIN-EDIT-INSPECTOR-PROFILE',
  component: () => import('../components/bfc/admin/users/inspector/create.vue')
},

{
  path: '/admin/users/create-inspector',
  name: 'ADMIN-NEW-INSPECTOR',
  component: () => import('../components/bfc/admin/users/inspector/create.vue')
},

{
  path: '/admin/users/inspector',
  name: 'ADMIN-INSPECTOR-LIST',
  component: () => import('../components/bfc/admin/users/inspector/inspector.vue')
},

{
  path: '/admin/users/inspector/setting-commission',
  name: 'inspector-commission',
  component: () => import('../components/bfc/admin/users/inspector/commission.vue')
},

{
  path: '/admin/view/:caseNo',
  name: 'ADMIN-VIEW-CASE',
  component: () => import('../components/bfc/admin/view/view-case.vue')
},
{
  path: '/admin/report',
  name: 'ADMIN-REPORT',
  component: () => import('../components/bfc/admin/report/report.vue')
},
{
  path: '/admin/ex/rate',
  name: 'ADMIN-EXCHANGE-RATE',
  component: () => import('../components/bfc/admin/ex/rate.vue')
},

//DOCTOR
{
  path: '/doctor',
  name: 'DR',
  component: () => import('../components/bfc/doctor/login/login.vue')
},
{
  path: '/doctor/forget',
  name: 'DR-FORGOT-PASSWORD',
  component: () => import('../components/bfc/doctor/forget/forget.vue')
},
{
  path: '/doctor/resetpassword/:code',
  name: 'DR-RESET-PASSWORD',
  component: () => import('../components/bfc/library/auth/reset-password.vue')
},
{
  path: '/doctor/dashboard',
  name: 'DR-DASHBOARD',
  component: () => import('../components/bfc/doctor/dashboard/dashboard.vue')
},
{
  path: '/doctor/new-case',
  name: 'DR-NEW-CASE',
  component: () => import('../components/bfc/doctor/form/form.vue')
},
{
  path: '/doctor/new-refinement/:caseNo/:sequence',
  name: 'DR-NEW-REFINEMENT',
  component: () => import('../components/bfc/doctor/form-refinement/form-refinement.vue')
},
{
  path: '/doctor/draft',
  name: 'DR-DRAFT-CASE',
  component: () => import('../components/bfc/doctor/form/form.vue')
},

{
  path: '/doctor/case/:caseNo',
  name: 'DR-VIEWCASE',
  component: () => import('../components/bfc/doctor/view-case/view-case.vue')
},
{
  path: '/doctor/checkout/:caseNo',
  name: 'DR-CHECKOUT',
  component: () => import('../components/bfc/doctor/checkout/checkout.vue')
},
{
  path: '/doctor/payment-status/:caseNo',
  name: 'DR-PAYMENT',
  component: () => import('../components/bfc/doctor/payment-status/payment-status.vue')
},
{
  path: '/doctor/update-aligner/:caseNo',
  name: 'DR-ALIGNER-LIST',
  component: () => import('../components/bfc/doctor/update-aligner/update-aligner.vue')
},
{
  path: '/doctor/update-aligner-step/:caseNo/:step',
  name: 'DR-UPDATE-ALIGNER-STEP',
  component: () => import('../components/bfc/doctor/update-aligner-step/update-aligner-step.vue')
},

{
  path: '/doctor/profile',
  name: 'DR-PROFILE',
  component: () => import('../components/bfc/doctor/profile/profile.vue')
},


// {
//   path: '/lab/uploadpdf/:caseid',
//   name: 'Doctor Checkout',
//   component: () => import('../components/bfc/doctor/upload-pdf/uploadpdf.vue')
// },

{
  path: '/doctor/feedback',
  name: 'DR-FEEDBACK',
  component: () => import('../components/bfc/doctor/feedback/feedback.vue')
},
{
    path: '/doctor/sign-up',
    name: 'DR-SIGNUP',
    component: () => import('../components/bfc/doctor/sign-up/sign-up.vue')
},
//---------------------------------
{
  path: '/lab',
  name: 'LAB',
  component: () => import('../components/bfc/lab/login/login.vue')
},
{
  path: '/lab/dashboard',
  name: 'LAB-DASHBOARD',
  component: () => import('../components/bfc/lab/dashboard/dashboard.vue')
},

{
  path: '/lab/view/:caseNo',
  name: 'LAB-VIEW-CASE',
  component: () => import('../components/bfc/lab/view/view-case.vue')
},

{
  path: '/lab/upload-pdf/:caseNo',
  name: 'LAB-UPLOAD-PDF',
  component: () => import('../components/bfc/lab/upload-pdf/uploadpdf.vue')
},

{
  path: '/lab/lab-upload/:caseNo',
  name: 'LAB-UPLOAD-MONITORING',
  component: () => import('../components/bfc/lab/lab-monitoring/lab-monitoring.vue')
},

{
  path: '/lab/shipping/:caseNo',
  name: 'LAB-SHIPPING-INFORMATION',
  component: () => import('../components/bfc/lab/shipping-information/shipping-information.vue')
},

{
  path: '/lab/update-link/:caseNo',
  name: 'LAB-UPDATE-LINK',
  component: () => import('../components/bfc/lab/update-link/update-link.vue')
},


{
  path: '/lab/select-package/:caseNo/:sequence',
  name: 'SELECT-PACKAGE',
  component: () => import('../components/bfc/lab/select-package/select-package.vue')
},

{
  path: '/lab/profile',
  name: 'LAB-PROFILE',
  component: () => import('../components/bfc/lab/profile/profile.vue')
},


// Inspector
//---------------------------------
{
  path: '/inspector',
  name: 'inspector',
  component: () => import('../components/bfc/inspector/login/login.vue')
},
{
  path: '/inspector/dashboard',
  name: 'inspector-DASHBOARD',
  component: () => import('../components/bfc/inspector/dashboard/dashboard.vue')
},

{
  path: '/inspector/view/:caseNo',
  name: 'inspector-VIEW-CASE',
  component: () => import('../components/bfc/inspector/view/view-case.vue')
},




{
  path: '/inspector/profile',
  name: 'inspector-PROFILE',
  component: () => import('../components/bfc/inspector/profile/profile.vue')
},

{
  path: '/admin/inspector/report',
  name: 'ADMIN-INSPECTOR-REPORT',
  component: () => import('../components/bfc/admin/report/inspector-report.vue')
},
{
  path: '/inspector/report',
  name: 'INSPECTOR-REPORT',
  component: () => import('../components/bfc/inspector/report/report.vue')
},


]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
